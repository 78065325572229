import {
  DesktopMediaQuery,
  TabletOrMobileMediaQuery,
} from '../ResponsiveWrappers'

export const SagaSection = () => {
  return (
    <>
      <DesktopMediaQuery>
        <p className="text-start text-xl xl:text-2xl md:my-5 ">
          Secure, user-friendly Seed <br />
          Vault and a blazing fast
          <br /> Web3 network, in the palm
          <br /> of your hand.
        </p>
      </DesktopMediaQuery>
      <TabletOrMobileMediaQuery>
        <p className="text-center sm:text-start text-xl my-4">
          Secure, user-friendly Seed <br />
          Vault and a blazing fast
          <br /> Web3 network, in the palm
          <br /> of your hand.
        </p>
      </TabletOrMobileMediaQuery>
    </>
  )
}
