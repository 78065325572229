import Image from 'next/legacy/image'

export const ShippingInfo = ({
  hasSagaPhoneEurope = false,
  hasSagaPhone = false,
  forceInfo = false,
}) => {
  const accessory = !hasSagaPhone && !hasSagaPhoneEurope
  return (
    <div className="flex items-start sm:items-center mt-2">
      <div className="text-[#14F195] mt-0.5 mr-2">
        <Image
          src={'/infoGreen.svg'}
          alt="shopping-cart"
          width={24}
          height={24}
        />
      </div>
      {hasSagaPhoneEurope && (
        <div className="text-sm text-[#8E8E93]">
          Due to supply chain challenges, Saga (EU) will be shipping on May 1,
          2023.
        </div>
      )}
      {(hasSagaPhone || forceInfo) && (
        <div className="text-sm text-[#8E8E93]">
          Saga is currently on backorder. We expect to start shipping orders
          again on May 1, 2023.
        </div>
      )}
      {accessory && (
        <div className="text-sm text-[#8E8E93]">
          Due to supply chain challenges, the Saga Case will be shipping on May
          8, 2023.
        </div>
      )}
    </div>
  )
}
