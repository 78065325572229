import type { GetServerSideProps, NextPage } from 'next'
import Link from 'next/link'
import Image from 'next/image'

import { ImageContainer } from '../components/ImageContainer'
import { ProductImageDetail } from '../components/Product'
import {
  filterSagaVariantByMarket,
  getHandleOrProductId,
  parseShopifyResponse,
  VariantWithProductId,
} from '../lib/shopify'
import { getSagaCollection } from '../lib/shopify/collections'
import { IMAGE_FALLBACK } from '../lib/utils'
import { AddToCart } from '../components/AddToCart'
import { withIronSessionSsr } from 'iron-session/next'
import { sessionOptions } from '../lib/session'
import { useCheckoutCart } from '../context/useCheckoutCart'
import { useEffect } from 'react'

import { SagaSection } from '../components/SagaSection'
import HTMLHead from '../components/HTMLHead'
import { PopUpLightbox } from '../components/PopUpModal'
import { SoldOut } from '../components/SoldOut'

const Home: NextPage<{
  variantWithProductId: VariantWithProductId
  verifiedWallet: string
}> = ({ variantWithProductId, verifiedWallet }) => {
  const { productVariant, setProductVariant, accessories } = useCheckoutCart()
  const { variant: mainProduct, productId } = variantWithProductId

  useEffect(() => {
    if (!productVariant?.product) {
      setProductVariant(variantWithProductId)
    }
  }, [productVariant, setProductVariant, variantWithProductId])

  const mainProductImage =
    mainProduct.product?.images?.[0].src ||
    mainProduct.image?.src ||
    IMAGE_FALLBACK

  return (
    <div className="flex flex-col">
      <HTMLHead title="Buy Saga and Accessories" />
      <section className="relative mb-[90px]">
        <div className="flex flex-col items-center lg:flex-row-reverse lg:gap-x-16 h-full justify-between md:mt-4">
          <div className="min-h-[380px] lg:min-h-[400px] aspect-[1/1] sm:aspect-[4/3] xl:aspect-[16/9] w-full sm:max-w-[744px] lg:max-w-6xl xl:max-w-8xl relative mb-10 lg:mb-0">
            <Link
              href={`/detail/${getHandleOrProductId({
                product: mainProduct.product,
                productId,
              })}`}
              className="cursor-pointer"
            >
              <ImageContainer src={mainProductImage} priority={true} />
            </Link>
          </div>
          <div className="flex gap-2 flex-col sm:flex-row lg:flex-col justify-evenly items-center w-full lg:px-0 lg:w-auto lg:items-start lg:min-w-fit">
            <div className="items-center flex flex-col sm:items-start">
              <div className="relative w-[8.5rem] h-[3rem] xl:w-[263px] xl:h-[93px]">
                <Image
                  src={'./SagaTitle.svg'}
                  fill
                  style={{
                    objectFit: 'contain',
                  }}
                  alt="saga-title"
                />
              </div>
              <SagaSection />
            </div>
            {mainProduct.available ? (
              <AddToCart
                variant={mainProduct}
                verifiedWallet={verifiedWallet}
                onHomeScreen
                showSignup
                forceInfo
              />
            ) : (
              <SoldOut onHomeScreen={true} />
            )}
          </div>
        </div>
      </section>
      <section className=" relative h-auto mb-[90px] flex flex-col">
        <div className="flex items-center flex-col w-full max-w-[750px] lg:max-w-full self-center">
          <span className="flex flex-col lg:flex-row mb-5 lg:mb-8 lg:items-end lg:gap-5 gap-3 self-start">
            <span className="text-4xl font-bold">Accessories</span>
            <span className="text-[#8E8E93] text-sm tracking-[.01em]">
              Made specifically for Saga
            </span>
          </span>
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-6 items-center">
            {accessories.products
              ?.sort(
                (productA, productB) =>
                  parseFloat(productA.variants[0].priceV2.amount) -
                  parseFloat(productB.variants[0].priceV2.amount)
              )
              .map((product, idx) => {
                const variant = product.variants[0]
                return (
                  <ProductImageDetail
                    variant={variant}
                    product={product}
                    key={`product-detail-${idx}`}
                  />
                )
              })}
          </div>
        </div>
        {mainProduct.available ? <PopUpLightbox /> : null}
      </section>
    </div>
  )
}

export const getServerSideProps: GetServerSideProps = withIronSessionSsr(
  async (context) => {
    const { locale } = context

    const { verifiedWallet } = context.req.session

    const sagaResponse = await getSagaCollection()

    let variantWithProductId: VariantWithProductId = {} as VariantWithProductId

    if (sagaResponse.success && sagaResponse.data) {
      try {
        variantWithProductId = filterSagaVariantByMarket(
          sagaResponse.data.products!,
          locale || 'US'
        ) as VariantWithProductId
      } catch (error) {
        return {
          notFound: true,
        }
      }
    } else {
      return {
        redirect: {
          statusCode: 301,
          destination: '/500',
        },
      }
    }

    variantWithProductId.variant.product = {
      ...variantWithProductId.product,
      variants: [],
    }

    // HACK: Force Saga unavailable
    variantWithProductId.product = {
      ...variantWithProductId.product,
      availableForSale: false
    }
    variantWithProductId.variant = {
      ...variantWithProductId.variant,
      available: false
    }
    variantWithProductId.variant.product = {
      ...variantWithProductId.variant.product,
      availableForSale: false
    }
    // END HACK

    return {
      props: {
        variantWithProductId: sagaResponse.success
          ? parseShopifyResponse(variantWithProductId)
          : {},
        verifiedWallet: verifiedWallet || '',
      },
    }
  },
  sessionOptions
)

export default Home
