import { useCallback, useEffect, useState } from 'react'
import Image from 'next/image'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'
import { soehne } from '../../fonts'

import useLocalStorage from '../../hooks/useLocalStorage'
import { useLightboxModalContext } from '../../providers/LightboxModalProvider'

const SOLSTICE_STORE_NAME = 'solsticeViewCount'
const MAX_VIEWS = 2

interface PopUpModalProps {
  open: boolean
  handleClose: () => void
}

export const PopUpModal = ({ open, handleClose }: PopUpModalProps) => {
  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className={`${soehne.variable} translate-x-[-50%] translate-y-[-50%] outline-none py-11 font-sans absolute bottom-auto top-[50%] left-[50%] w-full md:max-w-[80%] bg-[#1C1C1E] rounded-[2rem]`}
      >
        <span
          onClick={handleClose}
          className="material-icons cursor-pointer absolute right-5 top-5"
        >
          <CloseIcon className={'text-xl text-[#636366]'} />
        </span>
        <div
          id="modal-modal-description"
          className="px-8 flex flex-col items-center text-center"
        >
          <div className="w-full min-h-fit landscape:max-h-[70vh]">
            <Image
              src={'/price_drop_modal_image.png'}
              alt="Logo of Solana Solstice"
              fill
              className="object-contain !relative rounded-[1rem] landscape:max-h-[70vh]"
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

interface SolsticeLightboxStore {
  viewCount: number
}

export const PopUpLightbox = () => {
  const [open, setOpen] = useState(false)
  const { wasOpen, setWasOpen } = useLightboxModalContext()
  const [solsticeViewCountStore, setSolsticeViewCountStore] =
    useLocalStorage<SolsticeLightboxStore>(SOLSTICE_STORE_NAME, {
      viewCount: 0,
    })

  const handleClose = useCallback(() => {
    const viewCount = solsticeViewCountStore.viewCount + 1
    setSolsticeViewCountStore({ viewCount })
    setOpen(false)
  }, [setSolsticeViewCountStore, solsticeViewCountStore.viewCount])

  useEffect(() => {
    if (!wasOpen && solsticeViewCountStore.viewCount <= MAX_VIEWS) {
      setWasOpen(true)
      setOpen(true)
    }
  }, [setWasOpen, solsticeViewCountStore.viewCount, wasOpen])

  return <PopUpModal open={open} handleClose={handleClose} />
}
