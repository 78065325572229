import React, { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { captureException } from '@sentry/nextjs'
import { useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { Variant } from 'shopify-buy'

import { GreenButton } from '../GreenButton'
import { useCheckoutCart } from '../../context/useCheckoutCart'
import { useVerifyWallet } from '../../context/useVerifyWallet'
import { useInfoBox } from '../../context/useInfoBox'
import { compareGID, getHandleOrProductId } from '../../lib/shopify'
import { getErrorMessage } from '../../lib/errors'
import { ShippingInfo } from './ShippingInfo'
import {
  SAGA_PHONE_EU_PRODUCT_ID,
  SAGA_PHONE_US_PRODUCT_ID,
} from '../../utils/constants'
import { ConnectButton } from '../ConnectButton'

/**
 * Activate or deactivate inventory messages component `ShippingInfo`.
 */
const SHOW_INVENTORY_MESSAGES = false

export interface VerifiedWalletProps {
  verifiedWallet: string
  variant: Variant
}

export interface AddToCartProps extends VerifiedWalletProps {
  showInfo?: boolean
  forceInfo?: boolean
  onHomeScreen?: boolean
  mainProductDetailsLink?: boolean
  showSignup?: boolean
  showFaq?: boolean
}

export const AddToCart = (props: AddToCartProps) => {
  const { updating, toggleCart } = useCheckoutCart()
  const { connected } = useWallet()
  const { setVerifiedWallet } = useVerifyWallet()
  const router = useRouter()

  const { enqueueInfo } = useInfoBox()
  const { handleLineItemsToAdd } = useCheckoutCart()

  useEffect(() => {
    if (!props.verifiedWallet) return

    setVerifiedWallet(new PublicKey(props.verifiedWallet))
  }, [props.verifiedWallet, setVerifiedWallet])

  const handleAddToCart: (variant: Variant, quantity: number) => void = async (
    variant,
    quantity
  ) => {
    try {
      handleLineItemsToAdd(variant, quantity, toggleCart)
    } catch (error) {
      const message = getErrorMessage(
        error,
        'There was a problem adding to your cart. Please refresh and try again.'
      )
      enqueueInfo(message, { variant: 'error' })
      captureException(error)
    }
  }

  const hasSagaPhoneEurope = useMemo(
    () => compareGID(props?.variant?.product?.id, SAGA_PHONE_EU_PRODUCT_ID),
    [props?.variant?.product?.id]
  )

  const hasSagaPhone = useMemo(
    () => compareGID(props?.variant?.product?.id, SAGA_PHONE_US_PRODUCT_ID),
    [props?.variant?.product?.id]
  )

  return (
    <div className="w-full flex flex-col gap-3 sm:max-w-[328px]">
      <div className={`w-full flex flex-col items-center md:items-start gap-3`}>
        {props.mainProductDetailsLink && (
          <GreenButton
            onClick={() =>
              router.push(
                `/detail/${getHandleOrProductId({
                  product: props.variant.product,
                  productId: props.variant.product.id,
                })}`
              )
            }
            extraClass="mb-3 self-center"
            inverted
          >
            View details and buy
          </GreenButton>
        )}

        {!props.mainProductDetailsLink ? (
          <GreenButton
            onClick={() => handleAddToCart(props.variant, 1)}
            fullWidth={!props.onHomeScreen}
            isLoading={updating}
          >
            Add to cart
          </GreenButton>
        ) : (
          props.showInfo && (
            <GreenButton fullWidth disabled>
              Add to cart
            </GreenButton>
          )
        )}
        {SHOW_INVENTORY_MESSAGES && (
          <ShippingInfo
            hasSagaPhoneEurope={hasSagaPhoneEurope}
            hasSagaPhone={hasSagaPhone}
          />
        )}
        {!connected && (
          <div
            className={`w-full flex mt-3 ${
              props.onHomeScreen && `items-center`
            }`}
          >
            <ConnectButton
              showAsLink={true}
              onHomeScreen={props.onHomeScreen}
            />
          </div>
        )}
      </div>
    </div>
  )
}
