import React, { FormEvent, useCallback, useState } from 'react'
import { DiscordButton, GreenButton, TwitterButton } from '../GreenButton'
import Image from 'next/image'
import Link from 'next/link'
import { targetBlank } from '../../utils/linkUtils'

type Props = Omit<
  React.ComponentProps<typeof GreenButton>,
  'content' | 'disabled'
> &
  Readonly<{
    onHomeScreen?: true
  }>

function SoldOutLeadGenForm() {
  const [email, setEmail] = useState('')
  const [emailIsValid, setEmailIsValid] = useState(false)
  const [formIsSubmitting, setFormIsSubmitting] = useState(false)
  const [submitResult, setSubmitResult] = useState<null | Readonly<{
    inlineMessage?: string
    errors?: readonly Readonly<{ message: string }>[]
  }>>(null)
  const handleFormSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      const hubspotPortalId = '22812690'
      // https://app.hubspot.com/forms/22812690/editor/67353a8c-bab8-4b15-add8-152ad509bd2c/edit/form
      const formId = '67353a8c-bab8-4b15-add8-152ad509bd2c'
      e.preventDefault()
      setFormIsSubmitting(true)
      setSubmitResult(null)
      try {
        const response = await fetch(
          `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${formId}`,
          {
            body: JSON.stringify({
              fields: [
                {
                  objectTypeId: '0-1' /* contact */,
                  name: 'email',
                  value: email,
                },
              ],
            }),
            headers: { 'content-type': 'application/json' },
            method: 'POST',
          }
        )
        const result = await response.json()
        if (result?.inlineMessage) {
          // Implies form success; reset the email.
          setEmail('')
        }
        setSubmitResult(result)
      } finally {
        setFormIsSubmitting(false)
      }
    },
    [email]
  )
  return (
    <>
      <span>Stay tuned for updates on Saga</span>
      <form
        className="flex flex-col w-full relative"
        onSubmit={handleFormSubmit}
      >
        <input
          className="bg-[#1C1C1E] rounded h-11 p-4 pe-12 placeholder:text-[#636366] w-full"
          disabled={formIsSubmitting}
          type="email"
          name="email"
          placeholder="Email"
          onChange={(e) => {
            setEmail(e.target.value)
            try {
              setEmailIsValid(e.target.checkValidity())
            } catch {
              setEmailIsValid(true)
            }
          }}
          required
          value={email}
        />
        <button
          className="disabled:brightness-50 disabled:grayscale hover:brightness-125 absolute p-2.5 end-1.5"
          type="submit"
          disabled={!emailIsValid || formIsSubmitting}
        >
          <Image
            alt="Sign up"
            height={24}
            src="/circle-right-arrow.svg"
            width={24}
          />
        </button>
        <span className="mt-2 text-sm max-w-xs text-gray-400">You agree to be contacted by Solana Mobile and have read our <Link className="text-white" href="https://solanamobile.com/privacy-policy-homepage-web" {...targetBlank}>Privacy Policy</Link>.</span>
        {submitResult?.errors && submitResult.errors.length ? (
          submitResult.errors.map(({ message }, ii) => (
            <p className="mt-2 text-sm text-[#FE7F7F]" key={ii} title={message}>
              Can you try that again?
            </p>
          ))
        ) : (
          <p className="mt-2 text-sm text-[#14F195]">
            {submitResult?.inlineMessage || '\xa0'}
          </p>
        )}
      </form>
    </>
  )
}

export function SoldOut(props: Props) {
  return (
    <div className="flex flex-col items-center gap-4">
      {props.onHomeScreen ? null : (
        <GreenButton {...props} disabled>
          Sold out
        </GreenButton>
      )}
      <SoldOutLeadGenForm />
      <div className="flex gap-4 w-full">
        <DiscordButton
          extraClass="text-sm hover:brightness-110 grow"
          onClick={() => {
            window.open('https://discord.gg/solanamobile', '_blank')
          }}
          size="small"
        >
          <Image
            className="inline-block align-text-bottom mr-2"
            alt="Discord logo"
            width={20}
            height={20}
            src={'/discord-mark-white.svg'}
          />{' '}
          Discord
        </DiscordButton>
        <TwitterButton
          extraClass="text-sm hover:brightness-125 border border-gray-500 grow"
          onClick={() => {
            window.open(
              'https://twitter.com/intent/follow?screen_name=solanamobile',
              '_blank'
            )
          }}
          size="small"
        >
          <Image
            className="inline-block align-text-bottom mr-2"
            alt="X logo"
            width={15}
            height={15}
            src={'/x-white-logo.svg'}
          />{' '}
          Follow us
        </TwitterButton>
      </div>
    </div>
  )
}
