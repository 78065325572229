import { APP_URL } from './constants'

export const siteConfig = {
  siteMetadata: {
    title: `Solana Mobile Store`,
    description: `Buy Saga, a premium Android mobile phone purpose-built to power web3. Self-custody on device with Seed Vault and access web3 native dApps. Enjoy incredible cameras, stunning display, and rear fingerprint sensor.`,
    socialshare: `https://solanamobile.com/solanamobile_socialshare.png`,
    author: `@solanamobile`,
  },
  siteUrl: APP_URL,
}
